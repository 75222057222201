<template>
  <page-manage-base>
    <template v-slot:title>{{ $route.name }}</template>
    <template v-slot:default>
      <v-card class="mx-10 mt-7" shaped tile>
        <Table
          :total="getWebRequestExperienceList"
          :headers="headers"
          :items="webrequestlistExperience"
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
            class: 'elevation-1 custom-table mt-n4',
          }"
          ref="table"
          :itemsPerPage="10"
          :funReset="loadList"
          :sortField="[]"
          :filter="filter"
          :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
          :multiSort="true"
        >
          <template v-slot:top>
            <v-row>
              <v-col :cols="10">
                <v-text-field
                  @change="searchText"
                  append-icon="mdi-magnify"
                  label="検索"
                  class="ml-8"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col :cols="2">
                <v-checkbox
                  class="ml-16"
                  v-model="getHandledExperience"
                  :label="
                    $t(
                      'webPageManage.VisitReservation.AlsoDisplayedAsSupported',
                    )
                  "
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.handledAt`]="{ item }">
            <v-chip
              :color="item.handledAt !== null ? '#616161' : '#DB3069'"
              class="status"
              label
              text-color="white"
            >
              {{ item.handledAt !== null ? '対応済' : '未対応' }}
            </v-chip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div v-if="item.createdAt !== null">
              {{ item.createdAt.substr(5, 2) }}/{{
                item.createdAt.substr(8, 2)
              }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div v-if="item.name !== null">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.tel`]="{ item }">
            <div v-if="item.tel !== null">
              {{ item.tel }}
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <div v-if="item.email !== null">
              {{ item.email }}
            </div>
          </template>
          <template v-slot:[`item.firstDate`]="{ item }">
            <div class="d-flex justify-start">
              <div v-if="item.firstDate !== null" class="mr-2">
                <span>
                  {{ item.firstDate.substr(5, 2) }}/{{
                    item.firstDate.substr(8, 2)
                  }}
                </span>
              </div>
              <div v-if="item.firstTime !== null">
                <span>{{ item.firstTime.substr(0, 5) }}</span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.secondDate`]="{ item }">
            <div class="d-flex justify-start">
              <div v-if="item.secondDate !== null" class="mr-2">
                <span>
                  {{ item.secondDate.substr(5, 2) }}/{{
                    item.secondDate.substr(8, 2)
                  }}
                </span>
              </div>
              <div v-if="item.secondTime !== null">
                <span>{{ item.secondTime.substr(0, 5) }}</span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn
              v-if="item.clientId !== null"
              class="t-btn--prm"
              icon
              :to="
                checkPerUser
                  ? '/customer-details/' +
                    item.clientId +
                    '?typeClient=' +
                    item.client.type
                  : ''
              "
              target="_blank"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.clientId`]="{ item }">
            <div class="d-flex justify-start">
              <span v-if="item.clientId === null">なし</span>
              <span v-if="item.clientId !== null && item.wasExistingClient"
                >紐づけ済み</span
              >
              <span v-if="item.clientId !== null && !item.wasExistingClient"
                >作成済み</span
              >
            </div>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              :disabled="!checkPerUser"
              class="btn-crm-primary"
              style="border: 0px"
              icon
            >
              <v-icon
                @click="
                  checkPerUser ? setData(item) : '',
                    (visible = 1),
                    (itemID = item.id)
                "
                x-large
                >mdi-pencil-circle</v-icon
              >
            </v-btn>
          </template>
        </Table>
        <experience-detail
          v-if="checkPerUser"
          :check="check"
          :data="data"
          :visible="visible === 1"
          @reset="$refs.table.reset()"
          @close="visible = -1"
        />
      </v-card>
    </template>
  </page-manage-base>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageManageBase from '../PageManageBase.vue';
import ExperienceDetail from './experienceDetail.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { WEBREQUEST_LIST_EXPERIENCE_ONLINECONSULTATION } from '@/api/graphql/dashboard/webPageManage/experience/index.js';
import gql from 'graphql-tag';
import moment from 'moment';
import Table from '@/components/Table/index.vue';

export default {
  name: 'NoticeList',
  data() {
    return {
      listSortField: [
        {
          colName: 'handledAt', // === value in headers
          name: 'handledAt',
          fieldName: 'webRequest',
        },
      ],
      filter: {
        keyword: '',
        handled: false,
      },
      search: '',
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      itemID: null,
      data: {},
      checked: false,
      visible: -1,
      headers: [
        {
          text: this.$t('webPageManage.experience.status'),
          fieldName: 'webRequest.isHandled',
          value: 'handledAt',
          align: 'center',
        },
        {
          text: this.$t('webPageManage.experience.date'),
          fieldName: 'webRequest.createdAt',
          value: 'createdAt',
        },
        {
          text: this.$t('webPageManage.experience.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('webPageManage.experience.telephoneNumber'),
          fieldName: 'webRequest.tel',
          value: 'tel',
        },
        {
          text: this.$t('webPageManage.experience.Email'),
          fieldName: 'webRequest.email',
          value: 'email',
        },
        {
          text: this.$t('webPageManage.experience.FirstChoiceDate'),
          fieldName: 'webRequest.firstDate',
          value: 'firstDate',
        },
        {
          text: this.$t('webPageManage.experience.secondChoiceDate'),
          fieldName: 'webRequest.secondDate',
          value: 'secondDate',
        },
        {
          text: 'オンライン相談詳細',
          sortable: false,
          value: 'edit',
          align: 'center',
        },
        {
          text: this.$t('webPageManage.experience.client'),
          sortable: false,
          value: 'clientId',
        },
        {
          text: this.$t('webPageManage.experience.CustomerDetails'),
          sortable: false,
          value: 'detail',
          align: 'center',
        },
      ],
      check: true,
    };
  },
  mounted() {
    if (this.$refs.table) {
      this.$refs.table.reset();
    }
  },
  computed: {
    ...mapGetters([
      'getWebRequestExperienceList',
      'webrequestlistExperience',
      'keywordExperience',
      'handledExperience',
    ]),
    // ====Can't find the word right away=====
    // getKeywordExperience: {
    //   get() {
    //     return this.keywordExperience;
    //   },
    //   set(value) {
    //     this.setKeywordExperience(value);
    //     this.filter.keyword = value;
    //     this.$refs.table.resetPageAndSearch();
    //   },
    // },

    getHandledExperience: {
      get() {
        return this.filter.handled === null;
      },
      set(value) {
        this.setHandledExperience(value);
        this.filter.handled = value ? null : false;
        this.$refs.table.resetPageAndSearch();
      },
    },
  },

  watch: {
    webrequestlistExperience() {
      this.check = !this.check;
      if (this.itemID) {
        this.setData({ id: parseInt(this.itemID) });
      }
    },
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions(['getWebrequestlistExperience']),
    ...mapMutations([
      'setCopyErrorText',
      'setWebRequestExperienceListTake',
      'setWebRequestExperienceListSkip',
      'setKeywordExperience',
      'setHandledExperience',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),

    async loadList(variables) {
      if (variables) {
        await this.getWebrequestlistExperience(variables);
      }
    },

    formatDate(date) {
      return moment(date).format('MM/YY HH:mm');
    },

    async setData(item) {
      const variables = {
        id: item.id,
      };
      this.$apollo
        .query({
          query: gql`
            ${WEBREQUEST_LIST_EXPERIENCE_ONLINECONSULTATION}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          this.data = data.data.webRequest;
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              WEBREQUEST_LIST_EXPERIENCE_ONLINECONSULTATION,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
      // this.visible = 1
      // this.itemID = item.id
    },
    // ======click search=====
    async searchText(v) {
      this.filter.keyword = v;
      this.$refs.table.resetPageAndSearch();
      // await this.setKeywordExperience(v);
      // this.getWebrequestlistExperience(this.orderBy);
    },
  },
  components: {
    PageManageBase,
    ExperienceDetail,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.notice {
  max-width: 1258px !important;
  margin-top: 15px;
  margin-left: 42px;
  .addNew {
    color: white;
    height: 28px !important;
    min-width: 40px;
  }
  .card-title {
    label {
      font-size: 13px;
    }
  }
  .custom-table {
    .status {
      height: 27px;
      width: 63px;
      border-radius: 6px !important;
    }
    button,
    a {
      height: 40px !important;
      width: 80px;
      font-size: 15px;
      color: white;
    }
  }
}
.v-data-table::v-deep {
  th,
  .v-data-footer,
  .v-select__selection {
    font-size: 14px !important;
  }
  .v-data-footer__pagination {
    display: none;
  }
}
::v-deep {
  .v-input--hide-details {
    .v-label {
      font-size: 15px !important;
      color: #757575 !important;
      font-weight: 400 !important;
    }
    .v-input__icon--append {
      i {
        color: #757575 !important;
      }
    }
  }
  .v-input--checkbox {
    .v-label {
      font-size: 18px !important;
      color: #666 !important;
      font-weight: 400 !important;
    }
    .v-icon__svg {
      color: #1cace0 !important;
    }
  }
  .v-data-table__wrapper {
    .v-data-table-header {
      tr {
        th {
          font-size: 14px !important;
          color: #424242 !important;
          font-weight: 400 !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 14px !important;
          color: #424242 !important;
          font-weight: 400 !important;
        }
      }
    }
  }
}
</style>
